<template>
  <div class="privacy page">
    <PrivacyContent1/>
    <NavFooter/>
  </div>
</template>

<script>
import PrivacyContent1 from '../components/PrivacyContent1'
import NavFooter from '../components/NavFooter'

export default {
  name: 'Privacy',
  components: {
    PrivacyContent1,
    NavFooter
  }
}
</script>

<style lang="scss" scoped>

</style>
