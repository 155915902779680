<template>
  <div class="terms">
    <h1 class="h2 t-1">Privacy Policy</h1>
    <hr class="line">
    <h2 class="h4 t-2">This is our updated Privacy Policy, taking effect on November 9, 2021.</h2>
    <div class="p-1">
      <div>Your privacy is critically important to us. At Roadhub, we have a few fundamental principles:</div>
      <br>
      <div>⦁ We are thoughtful about the personal information we ask you to provide and the personal information that we collect about you through the operation of our services.</div>
      <br>
      <div>⦁ We store personal information for only as long as we have a reason to keep it.</div>
      <br>
      <div>⦁ We aim to make it as simple as possible for you to control what information on your website is shared publicly (or kept private), indexed by search engines, and permanently deleted.</div>
      <br>
      <div>⦁ We help protect you from overreaching government demands for your personal information.</div>
      <br>
      <div>⦁ We aim for full transparency on how we gather, use, and share your personal information.</div>
      <br>
      <div>Below is our Privacy Policy, which incorporates and clarifies these principles.</div>
      <br>
      <div class="h4">What This Policy Covers</div>
      <br>
      <div>This Privacy Policy applies to information that we collect about you when you use our website: https://roadhub.design/.</div>
      <br>
      <div>This Privacy Policy also applies to information we collect when you apply for a job at Roadhub or one of our subsidiaries.</div>
      <br>
      <div>Throughout this Privacy Policy we’ll refer to our website, and other products and services collectively as “Services.”<br><br>Please note that this Privacy Policy does not apply to any of our products or services, like LinkedIn, that have a separate privacy policy.</div>
      <br>
      <div>Below we explain how we collect, use, and share information about you, along with the choices that you have with respect to that information.</div>
      <br>
      <div class="h4">Information We Collect</div>
      <br>
      <div>We only collect information about you if we have a reason to do so — for example, to provide our Services, to communicate with you, or to make our Services better.<br><br>We collect this information from three sources: if and when you provide information to us, automatically through operating our Services, and from outside sources. Let’s go over the information that we collect.</div>
      <br>
      <div class="h4">Information You Provide to Us</div>
      <br>
      <div>It’s probably no surprise that we collect information that you provide to us directly. Here are some examples:</div>
      <br>
      <div>⦁ Payment and contact information: If you buy something from us we’ll collect information to process those payments and contact you. You’ll provide additional personal and payment information like your name, credit card information, and contact information. We also keep a record of the purchases you’ve made.</div>
      <br>
      <div>⦁ Communications with us: When you communicate with us via form, email, phone, or otherwise, we store a copy of our communications.</div>
      <br>
      <div>⦁ Job applicant information: If you apply for a job with us — awesome! You may provide us with information like your name, contact information, resume or CV, and work authorization verification as part of the application process.</div>
      <br>
      <div class="h4">Information We Collect Automatically</div>
      <br>
      <div>We also collect some information automatically:</div>
      <br>
      <div>⦁ Log information: Like most online service providers, we collect information that web browsers, mobile devices, and servers typically make available, including the browser type, IP address, unique device identifiers, language preference, referring site, the date and time of access, operating system, and mobile network information. We collect log information when you use our Services.</div>
      <br>
      <div>⦁ Location information: We may determine the approximate location of your device from your IP address. We collect and use this information to, for example, calculate how many people visit our Services from certain geographic regions.</div>
      <br>
      <div>⦁ Information from cookies & other technologies: A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. Roadhub uses cookies to help us identify and track visitors, usage, and access preferences for our Services, as well as track and understand email campaign effectiveness.</div>
      <br>
      <div class="h4">How and Why We Use Information</div>
      <br>
      <div>We use information about you for the purposes listed below:</div>
      <br>
      <div>⦁ To ensure quality, maintain safety, and improve our Services. For example, by providing automatic upgrades and new versions of our Services. Or, for example, by monitoring and analyzing how users interact with our Services so we can create new features that we think our users will enjoy and that will help them create and manage websites more efficiently or make our Services easier to use.</div>
      <br>
      <div>⦁ To market our Services and measure, gauge, and improve the effectiveness of our marketing. For example, by targeting our marketing messages to groups of our users (like those who have a particular plan with us or have been users for a certain length of time), advertising our Services, analyzing the results of our marketing campaigns (like how many people purchased a paid plan after receiving a marketing message), and understanding and forecasting user retention.</div>
      <br>
      <div>⦁ To protect our Services, our users, and the public. For example, by detecting security incidents; detecting and protecting against malicious, deceptive, fraudulent, or illegal activity; fighting spam; complying with our legal obligations; and protecting the rights and property of Roadhub and others, which may result in us, for example, declining a transaction or terminating Services.</div>
      <br>
      <div>⦁ To fix problems with our Services. For example, by monitoring, debugging, repairing, and preventing issues.</div>
      <br>
      <div class="h4">Sharing Information</div>
      <br>
      <div>We share information about you in limited circumstances, and with appropriate safeguards on your privacy.</div>
      <br>
      <div>⦁ Subsidiaries and independent contractors: We may disclose information about you to our subsidiaries and independent contractors who need the information to help us provide our Services or process the information on our behalf. We require our subsidiaries and independent contractors to follow this Privacy Policy for any personal information that we share with them.</div>
      <br>
      <div>⦁ Third-party vendors: We may share information about you with third-party vendors who need the information in order to provide their services to us, or to provide their services to you or your site.</div>
      <br>
      <div>⦁ Legal and regulatory requirements: We may disclose information about you in response to a subpoena, court order, or other governmental request.</div>
      <br>
      <div>⦁ To protect rights, property, and others: We may disclose information about you when we believe in good faith that disclosure is reasonably necessary to protect the property or rights of Roadhub, third parties, or the public at large.</div>
      <br>
      <div>⦁ Business transfers: In connection with any merger, sale of company assets, or acquisition of all or a portion of our business by another company, or in the unlikely event that Roadhub goes out of business or enters bankruptcy, user information would likely be one of the assets that is transferred or acquired by a third party. If any of these events were to happen, this Privacy Policy would continue to apply to your information and the party receiving your information may continue to use your information, but only consistent with this Privacy Policy.</div>
      <br>
      <div>⦁ With your consent: We may share and disclose information with your consent or at your direction. For example, we may share your information with third parties when you authorize us to do so.</div>
      <br>
      <div>⦁ Published support requests: If you send us a request for assistance (for example, via a support email or one of our other feedback mechanisms), we reserve the right to publish that request in order to clarify or respond to your request, or to help us support other users.</div>
      <br>
      <div>We have a long-standing policy that we do not sell our users’ data. We aren’t a data broker, we don’t sell your personal information to data brokers, and we don’t sell your information to other companies that want to spam you with marketing emails.</div>
      <br>
      <div class="h4">How Long We Keep Information</div>
      <br>
      <div>We generally discard information about you when it’s no longer needed for the purposes for which we collect and use it — described in the section above on How and Why We Use Information — and we’re not legally required to keep it. For example, we keep web server logs that record information about a visitor to one of Roadhub’s websites, like the visitor’s IP address, browser type, and operating system, for approximately 30 days. We retain the logs for this period of time in order to, among other things, analyze traffic to Roadhub’s websites and investigate issues if something goes wrong on one of our websites.</div>
      <br>
      <div class="h4">Security</div>
      <br>
      <div>While no online service is 100% secure, we work very hard to protect information about you against unauthorized access, use, alteration, or destruction, and take reasonable measures to do so. We monitor our Services for potential vulnerabilities and attacks.</div>
      <br>
      <div class="h4">Choices</div>
      <br>
      <div>Set your browser to reject cookies: You can usually choose to set your browser to remove or reject browser cookies before using Roadhub’s website, with the drawback that certain features of Roadhub’s website may not function properly without the aid of cookies.</div>
      <br>
      <div class="h4">Your Rights</div>
      <br>
      <div>If you are located in certain parts of the world that fall under the scope of the European General Data Protection Regulation (aka the “GDPR”), you may have certain rights regarding your personal information, like the right to request access to or deletion of your data.</div>
      <br>
      <div>European General Data Protection Regulation (GDPR)</div>
      <br>
      <div>⦁ Request access to your personal data;</div>
      <br>
      <div>⦁ Request correction or deletion of your personal data;</div>
      <br>
      <div>⦁ Object to our use and processing of your personal data;</div>
      <br>
      <div>⦁ Request that we limit our use and processing of your personal data; and</div>
      <br>
      <div>⦁ Request portability of your personal data.</div>
      <br>
      <div>You also have the right to make a complaint to a government supervisory authority.</div>
      <br>
      <div class="h4">Privacy Policy Changes</div>
      <br>
      <div>Although most changes are likely to be minor, Roadhub may change its Privacy Policy from time to time. Roadhub encourages visitors to frequently check this page for any changes to its Privacy Policy.</div>
      <br>
      <div class="h4">How to Reach Us</div>
      <br>
      <div>If you have a question about this Privacy Policy, or you would like to contact us about any of the rights mentioned in the Your Rights section above, please contact us through our web form.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsContent1'
}
</script>

<style lang="scss" scoped>
.terms {
  @include grid;
  margin-top: 174px;
  margin-bottom: 128px;
  @include laptop {
    margin-top: 144px;
    margin-bottom: 96px;
  }
  @include tablet {
    margin-top: 128px;
    margin-bottom: 64px;
  }
  .t-1 {
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    margin-bottom: 62px;
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .line {
    grid-column: 2 / 14;
    grid-row: 2 / 3;
    margin-bottom: 32px;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-bottom: 26px;
    }
  }
  .t-2 {
    grid-column: 2 / 7;
    grid-row: 3 / 4;
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    @include tablet {
      margin-top: 32px;
      grid-column: 2 / 8;
      grid-row: 5 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
}

</style>
